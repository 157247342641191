import {useState} from "react";


const useElement = ( elementDefault = '' ) => {

    const [element ,  setElement] = useState(elementDefault)
    const handle = (event) => setElement( event.target.value )

    return [ element , handle ]
}

const Form = ({next}) => {

    // let security = [
    //     { "value" : "persona_1" , "title" : "Talento humano" },
    //     { "value" : "persona_2" , "title" : "Seguridad" },
    //     { "value" : "persona_3" , "title" : "Compras y administración" },
    // ]
    // let [services , setServices ]= useState([
    //     { "value" : "Vigilancia humana" , "title" : "Vigilancia humana" , "isChecked" : false },
    //     { "value" : "Seguridad electrónica" , "title" : "Seguridad electrónica" , "isChecked" : false },
    //     { "value" : "Escolta a carga" , "title" : "Escolta a carga" , "isChecked" : false },
    //     { "value" : "Poligrafías" , "title" : "Poligrafías" , "isChecked" : false },
    //     { "value" : "Estudio de confiabilidad a personas" , "title" : "Estudio de confiabilidad a personas" , "isChecked" : false },
    // ])
    let [reviewQuestion, setReviewQuestion ] = useState(false);

    let [ name , setName ] = useElement();
    let [ email , setEmail ] = useElement();
    let [ work , setWork ] = useElement();
    let [ phone , setPhone ] = useElement();
    // let [ info , setInfo ] = useElement();
    // let [ type , setType ] = useElement();
    let [ accept , setAccept ] = useElement();
    let emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;

    // let updateService = ( i ) => {
    //     let tmp = services.map( x => x );
    //     tmp[i].isChecked = !tmp[i].isChecked;
    //     setServices(tmp)
    // }

    let submitForm = async ( submit ) => {
        submit.preventDefault();
        setReviewQuestion(true)

        if (
            name !== "" &&
            email !== "" &&
            phone !== "" &&
            // type !== "" &&
            work !== ""
            //  services.filter( service => service.isChecked ).length > 0
        ) {
            // Review zap other data

            await fetch('https://hooks.zapier.com/hooks/catch/4068335/b68pwza/',{
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'no-cors', // no-cors, *cors, same-origin
                body: JSON.stringify({
                    name: name,
                    email: email,
                    phone: phone,
                    // info: info,
                    // type: type,
                    work: work,
                    // services: services.filter( service => service.isChecked ).map( service => service.value )
                })
            }).then( response => response.text() )

            setReviewQuestion(true)
            next()
        }



    }


    return (<div>
        <form className="mt-8" onSubmit={submitForm}>
            <div className="text-gray-600 font-sans text-center">
                <strong>¡GENIAL!</strong>
                <div>Estás a un paso de conocer el nivel de seguridad implementado en tu empresa.</div>
                <div>Regístrate aquí y obten el resultado.</div>
            </div>
            <div className="mx-auto max-w-lg ">
                <div className="py-1">
                    <span className="px-1 text-sm text-gray-600">Nombre</span>
                    <input placeholder="" type="text" value={name} onChange={setName}
                           className="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none"/>
                    { (reviewQuestion && name === "") ? <span className="text-xs text-red-700" >Diligencie el nombre</span> : "" }
                </div>
                <div className="py-1">
                    <span className="px-1 text-sm text-gray-600">Email</span>
                    <input placeholder="" type="email" value={email} onChange={setEmail}
                           className="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none"/>
                    { (reviewQuestion && email === "") ? <div className="text-xs text-red-700" >Diligencie el Correo electronico </div> : "" }
                    { (reviewQuestion && email !== "" && emailRegex.test(email) === false ) ? <div className="text-xs text-red-700" > El correo electronico no es correcto.</div> : "" }
                </div>
                <div className="py-1">
                    <span className="px-1 text-sm text-gray-600">Empresa</span>
                    <input placeholder="" type="text" value={work} onChange={setWork}
                           className="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none"/>
                    { (reviewQuestion && work === "") ? <span className="text-xs text-red-700" >Diligencie la empresa</span> : "" }
                </div>
                {/*<div className="py-1">
                    <span className="px-1 text-sm text-gray-600">¿Con qué perfil se identifica más?</span>
                    <select value={type} onChange={setType} className="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none">
                        <option value="">¿Con qué perfil se identifica más?</option>
                        {security.map( ( item , i ) => {
                            return <option key={i} value={item.value}>{item.title}</option>
                        })}
                    </select>
                    { (reviewQuestion && type === "") ? <span className="text-xs text-red-700" >Seleccione el perfil identificado</span> : "" }
                </div>*/}
                <div className="py-1">
                    <span className="px-1 text-sm text-gray-600">Celular</span>

                    <input placeholder="" type="tel" value={phone} onChange={setPhone}
                           className="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none"/>
                    { (reviewQuestion && phone === "") ? <span className="text-xs text-red-700" >Ingresa el numero de celular</span> : "" }
                </div>

                {/*<div className=" mt-6">
                    <span className="px-1 text-sm text-gray-600">¿Servicio(s) de interés?</span>
                    {services.map( (service , i) => {
                        return <label className="flex items-center" key={i}>
                            <input type="checkbox" className="form-checkbox" value={service.value} checked={service.isChecked} onChange={() => updateService(i)}/>
                            <span className="ml-2"> {service.title}</span>
                        </label>
                    })}
                    { (reviewQuestion && services.filter( service => service.isChecked ).length === 0) ? <span className="text-xs text-red-700" >Seleccione los servicios de interes.</span> : "" }
                </div>

                <label className="block my-6">
                    <span className="px-1 text-sm text-gray-600">Información Adicional</span>
                    <textarea value={info} onChange={setInfo} className="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none" rows="2" placeholder="Información Adicional*"/>
                </label>*/}

                <label className="block">
                    <span className="text-gray-500">Protevis LTDA se compromete a proteger su privacidad, solo usaremos sus datos para administrar su cuenta y proporcionar contenido que pueda ser de su interés. No compartiremos sus datos con terceros. </span>
                </label>

                <div className="justify-start my-4">
                    <label className="block text-gray-500 font-bold flex items-center">
                        <input className="leading-loose text-pink-600 top-0" type="checkbox" value={accept} onChange={setAccept} />
                        <span className="ml-2 text-sm py-2 text-gray-600 text-left">Acepto recibir otras comunicaciones de Protevis LTDA. </span>
                    </label>
                    <div className={"block w-full"}>
                        { (reviewQuestion && name === "") ? <span className="text-xs text-red-700" >Seleccione los servicios de interes.</span> : "" }
                    </div>
                </div>
                <div className="flex justify-start">
                    <span className="ml-2 text-sm py-2 text-gray-600 text-left">Puedes darte de baja de estas comunicaciones en cualquier momento. Para más información consulta
                          <a href="https://www.protevis.com/politica-privacidad" target="_blank" className="text-black border-b-2 border-gray-200 hover:border-gray-500"> Política de privacidad. </a>
                    </span>
                </div>
                <button
                    className="text-purple-500 bg-transparent border border-solid border-purple-500 hover:bg-purple-500 hover:text-white w-full active:bg-purple-600 font-bold uppercase text-sm px-14 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    onSubmit={submitForm}
                >
                    Registrar
                </button>
            </div>
        </form>
    </div>)
}

export default Form;