import 'zingchart/es6';
import ZingChart from 'zingchart-react';

const Tachometer = ({questions}) => {

    let values = questions.filter( value => { return value === "1" })

    let TextLevel = () => {
         if (values.length <= 8 )
             return <div>
                 ¡Es momento de ponerse las pilas! Contar con un buen nivel de seguridad en tu empresa te permitirá anticiparte a cualquier tipo de riesgo. En Protevis somos expertos en identificarlos y plantear estrategias para buscar la mejor opción para tu organización. Trabajemos de la mano.
                 Conoce más aquí <a href={"https://www.protevis.com/"} className={"underline color-red"} target={"_blank"}>www.protevis.com</a>
             </div>
         else if (values.length > 8 && values.length <= 12  )
             return <div>
                 ¡Vas por buen camino, pero aún puedes hacer mucho más! Es importante que sigas fortaleciendo el sistema de seguridad de tu empresa, puedes hacerlo a través de los servicios que hemos diseñado para ti. Permítenos acompañarte en este proceso de mejora.
                 Conoce más de nosotros en <a href={"https://www.protevis.com/"} className={"underline color-red"} target={"_blank"}>www.protevis.com</a>
             </div>
         else if (values.length > 12 )
             return <div>
                 ¡Excelente! Nos alegra saber lo importante que es para ti la seguridad de tu empresa. En Protevis podemos acompañarte a pulir algunos aspectos que te permitirán seguir creciendo. Trabajemos de la mano.
                 Conoce más sobre nosotros en <a href={"https://www.protevis.com/"} className={"underline color-red"} target={"_blank"}>www.protevis.com</a>
             </div>
    }

    return (
        <div className={"w-96"}>
            <h1 className="text-lg py-2 text-gray-600 text-center">El resultado de tu test de seguridad es: </h1>

            <ZingChart data={{
                type: "gauge",
                globals: {
                    fontSize: 25
                },
                plotarea: {
                    marginTop: 80
                },
                plot: {
                    size: '100%',
                    valueBox: {
                        placement: 'center',
                        text: '%v',
                        fontSize: 35,
                        rules: [
                            {
                                rule: '%v >= 13',
                                text: '%v<br>Excelente'
                            },
                            {
                                rule: '%v < 12 && %v >= 9',
                                text: '%v<br>Medio'
                            },
                            {
                                rule: '%v <= 8',
                                text: '%v<br>Bajo'
                            }
                        ]
                    }
                },
                tooltip: {
                    borderRadius: 5
                },
                scaleR: {
                    aperture: 180,
                    minValue: 0,
                    maxValue: 20,
                    step: 2,
                    center: {
                        visible: false
                    },
                    item: {
                        offsetR: 0,
                        rules: [{
                            rule: '%i == 9',
                            offsetX: 15
                        }]
                    },
                    tick: {
                        visible: false
                    },
                    labels: ['', '', '', '6', '8', '',  '12', '', '16', '', '', ''],
                    ring: {
                        size: 50,
                        rules: [
                            {
                                rule: '%v <= 7',
                                backgroundColor: '#E53935'
                            },
                            {
                                rule: '%v >= 8 && %v <= 12',
                                backgroundColor: '#FFA726'
                            },
                            {
                                rule: '%v > 12',
                                backgroundColor: '#29B6F6'
                            }
                        ]
                    }
                },
                series: [{
                    values: [values.length], // starting value
                    backgroundColor: 'black',
                    indicator: [10, 10, 10, 10, 0.75],
                    animation: {
                        effect: 2,
                        method: 1,
                        sequence: 4,
                        speed: 900
                    },
                }]
            }}/>


            <div className={"text-div-botton"}>
                <TextLevel />
            </div>

        </div>

    );
}

export default Tachometer;