import '../resouse/style/App.css';
import { useEffect, useState} from "react";
import Error from "../component/error";
import Tachometer from "../component/Tachometer";
import Form from "../component/Form";

let questions =
    [
        {"label" : "¿Cuentas con un CCTV que esté en buen estado y que cubra la totalidad de las instalaciones?",},
        {"label" : "¿Realizas el monitoreo constante del CCTV?",},
        {"label" : "¿Cuentas con procedimientos documentados de control de acceso?",},
        {"label" : "¿Realizas inventarios periódicos de la mercancía?",},
        {"label" : "¿Realizas recorridos permanentes que cubren completamente las instalaciones?",},
        {"label" : "¿Las barreras perimetrales cuentan con sistema de identificación o retardo en caso de intrusión?",},
        {"label" : "¿Las barreras perimetrales tienen la altura suficiente?",},
        {"label" : "¿Cuentas con alguna certificación o programa de seguridad física?",},
        {"label" : "¿Los controles de acceso son sistematizados?",},
        {"label" : "¿Realizas la inspección de los vehículos antes de ser cargados?",},
        {"label" : "¿Procedes siempre a precintar el vehículo después de ser cargado?",},
        {"label" : "¿El alumbrado público interno trabaja adecuadamente sin presentar fallas",},
        {"label" : "¿Todas tus instalaciones cuentan con sistemas de alarma?",},
        {"label" : "¿Cuentas con brigada de emergencias ?",},
        {"label" : "¿Los brigadistas son fácilmente reconocibles?",},
        {"label" : "¿Llevas un estricto control de ingreso y salida de activos ?",},
        {"label" : "¿Cuentas con medidas o control de identificación de visitantes ?",},
        {"label" : "¿Tienes identificadas las áreas críticas ?",},
        {"label" : "¿Los perfiles del personal de vigilancia se ajustan a tu actividad económica?",},
    ]

let App =  function  () {

    let [question ] = useState(questions);
    let [responseQuestion , setResponseQuestion] = useState(Array.from({length: questions.length }, () => null) );
    let [stepQuestion , setStepQuestion] = useState(0);
    let [step , setStep] = useState(0);
    let [error, setError] = useState(false);
    let [status , setStatus] = useState(null);

    let handleNext = () => {
        setError(false);
        if ( responseQuestion[stepQuestion] == null )
            setError(true);
        else {
            setStepQuestion(stepQuestion + 1)
            setStatus( null );
            if ( stepQuestion === question.length - 1 )
                setStep( step + 1 )
        }
    }

    let next = () =>  {
        setStep( step + 1 )
    }

    let handleBack = () => {
        setError(false);
        setStepQuestion(stepQuestion - 1 )
        setStatus( responseQuestion[stepQuestion - 1 ] )

    }

    let handleStep = ( e ) => {
        let tmp = responseQuestion;
        tmp[stepQuestion] = e.target.value;
        setResponseQuestion( tmp );
        setStatus(e.target.value);
    }

    let stepComponent = () => {
        switch ( step ) {
            case  0:
                return <div>
                    <div className={"text-center font-sans grayscale"}>
                        Con este test podrás medir el nivel de seguridad de tu empresa anticipándote y tomando medidas preventivas a tiempo,
                        es muy fácil solo debes responder unas pocas preguntas.
                    </div>

                    <div className={"text-center pt-4"}>
                        <button
                            className=" mx-3 bg-purple-500 text-white active:bg-purple-600 font-bold uppercase text-sm px-14 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={next}>
                            ¡COMENCEMOS!
                        </button>
                    </div>

                </div>
            case 1 :
                return <div className=" divide-gray-200">
                    <div className="border shadow rounded-md p-4 max-w-sm w-full mx-auto font-sans">
                        <div className={"text-center"}>
                            {question[stepQuestion].label}
                        </div>
                        <div className="mt-5 flex">
                            <label className=" text-center items-center block w-1/2">
                                <input type="radio" className="form-radio" name="radio" value="1" checked={status === "1"} onChange={handleStep}/>
                                <span className="ml-2">Si</span>
                            </label>
                            <label className=" text-center items-center block w-1/2">
                                <input type="radio" className="form-radio" name="radio" value="0" checked={status === "0"} onChange={handleStep}/>
                                <span className="ml-2">No</span>
                            </label>
                        </div>
                    </div>

                    <div className="pt-4 pr-4 pl-4">
                        <div className="pt-4 ">
                            <div className="flex p-2">
                                <div className="flex space-x-2.5">
                                    <button
                                        className=" text-purple-500 bg-transparent border border-solid border-purple-500 hover:bg-purple-500 hover:text-white active:bg-purple-600 font-bold uppercase text-sm px-14 py-3 rounded outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={handleBack}
                                        disabled={stepQuestion === 0}
                                    >
                                        Volver
                                    </button>

                                    <button
                                        className="  bg-purple-500 text-white active:bg-purple-600 font-bold uppercase text-sm px-14 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={handleNext}>
                                        Siguiente
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>

                    { (error) ? <Error category={"0"} className={"pt-3"} title={"Diligencia una de las opciones"} /> : <div/> }

                </div>
            case 2:
                return <Form next={next} />
            case 3:
                return <Tachometer questions={responseQuestion} />
        }
    }

    useEffect(()=> {
        document.title = "Test de seguridad"
    },[])

    return (
        <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
            <div className="relative py-3 sm:max-w-xl sm:mx-auto">
                <div className="absolute inset-0 bg-gradient-to-r from-cyan-400 to-sky-500 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl" />
                <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
                    <div className={"pb-10"} >
                        <img src={"./protevis.jpg"} alt={"imagen protevis"} className={"m-auto"}/>
                    </div>
                    <div className="max-w-md mx-auto">
                        {stepComponent()}
                    </div>
                </div>
            </div>
        </div>

    );
}

export default App;

